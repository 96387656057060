<template>
  <login-layout>
    <div class="panel">
      <h2>Wachtwoord vergeten</h2>
      <p>
        Weet je het wachtwoord niet meer?<br />
        Vul hieronder je e-mailadres in. We sturen dan binnen enkele minuten een e-mail waarmee een nieuw wachtwoord kan worden aangemaakt.
      </p>
    </div>
    <div>
      <validation-observer ref="forgotPasswordForm" v-slot="{ passes }">
        <b-form @submit.stop.prevent="passes(onSubmit)">
          <div v-if="alertMessage" :class="`alert ${alertType}`">{{ alertMessage }}</div>

          <div class="form-group">
            <ValidationProvider v-slot="v" name="E-mail" rules="required|email">
              <b-form-input v-model="username" type="text" class="form-control" placeholder="E-mail" :state="getValidationState(v)" />
              <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </div>
          <button type="submit" class="btn btn-success" :disabled="submitSucceeded">Verzenden</button>
        </b-form>
      </validation-observer>
    </div>
  </login-layout>
</template>

<style lang="scss" scoped>
.forgot {
  text-align: left;
  margin-bottom: 30px;

  a {
    color: #777777;
    font-size: 14px;
    text-decoration: underline;
  }

  p {
    overflow-wrap: normal;
  }
}
</style>

<script>
import { mapState } from "vuex";
import LoginLayout from "@/components/authentication/LoginLayout.vue";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import ValidationMixin from "@/mixins/validation-mixin";
const UserRepository = RepositoryFactory.get("UserRepository");

export default {
  components: { LoginLayout },
  mixins: [ValidationMixin],

  data() {
    return {
      username: "",
      submitSucceeded: false
    };
  },

  computed: {
    ...mapState("Alert", { alertType: "type", alertMessage: "message" })
  },

  methods: {
    onSubmit() {
      this.$refs.forgotPasswordForm.validate().then((success) => {
        if (!success) {
          return;
        }

        this.requestPasswordReset();

        this.$nextTick(() => {
          this.$refs.forgotPasswordForm.reset();
        });
      });
    },

    requestPasswordReset: function () {
      UserRepository.passwordReset(this.username)
        .then(() => {
          this.submitSucceeded = true;
          this.$store.dispatch("Alert/success", this.$t("user.password_forgot_success"));
        })
        .catch(() => {
          this.$store.dispatch("Alert/error", this.$t("general_error_title"));
        });
    }
  }
};
</script>
